export const TabKeys = {
  COMPANY_TAB_CHANGE: "COMPANY_TAB_CHANGE",
  GIFTCARD_TAB_CHANGE: "GIFTCARD_TAB_CHANGE",
  TERMS_TAB_CHANGE: "TERMS_TAB_CHANGE",
  TERMS_SEQ: "TERMS_SEQ",
  TERMS_CONTENTS: "TERMS_CONTENTS",
  VIEW_POSITION: "VIEW_POSITION"
} as const

export type TAB_KEY = typeof TabKeys[keyof typeof TabKeys];
