import dynamic from "next/dynamic";

export enum ModalTypes {
  ALERT,
  CONFIRM,
  GIFTCARDCHARGE,
  GIFTCARDCHARGERESULT,
  GIFTCARDCHARGEVERIFY,
  PAYSBOOKNLIFECARDINFO,
  BOOKNPAYGUIDE,
  SELFAUTH,
  UNLOCKCASH,
  CELLPHONEAUTH,
  HISTORYDETAIL,
  DORMANCY_RELEASE,
  USER_VERIFICATION,
  THIRD_PARTY_OFFER,
  PUSH_AGREE,
  RECOMMEND_CASH_UNLOCK,
  CHANGE_PUSH_AGREE,
  KAKAO_AUTH,
  KAKAO_AUTH_CONFIRM,
  TERMS_AGREE,
  USE_DETAIL,
  MAIN_POPUP,
  DEPOSIT_WITHOUT_BANKBOOK,
  FAQ_DETAIL,
  EXCHANGE_CONFIRM,
  GIFT_RETRY_MODAL,
  SELECT_REFUND_BANK,
  GIFT_RETRY_CONTACT,
  DEPOSIT_CANCEL_MODAL,
  DEPOSIT_CANCEL_MODAL_TEXT,
  SELECT_ACCOUNT,
  CASH_LIMIT,
  BULK_EXCHANGE,
  BULK_EXCHANGE_RESULT,
  CASH_LIMIT_CONFIRM,
  INICIS_AGREEMENT_POPUP,
  INICIS_AGREEMENT_CONTENT_POPUP,
  CASHLOCK_ONE_HOUR_AGREE_POPUP,
  RECAPTCHA,
}

export type TModalState = {
  type: ModalTypes;
  component: any;
};

export const ModalTable: TModalState[] = [
  {
    type: ModalTypes.ALERT,
    component: dynamic(() => import("../../modal/CustomAlertModal")),
  },
  {
    type: ModalTypes.GIFTCARDCHARGE,
    component: dynamic(
      () => import("../../modal/GiftCard/GiftCardMultipleChargeModal")
    ),
  },
  {
    type: ModalTypes.GIFTCARDCHARGERESULT,
    component: dynamic(
      () => import("../../modal/GiftCard/GiftCardMultipleChargeResultModal")
    ),
  },
  {
    type: ModalTypes.GIFTCARDCHARGEVERIFY,
    component: dynamic(
      () => import("../../modal/GiftCard/GiftCardMultipleChargeVerifyModal")
    ),
  },
  {
    type: ModalTypes.PAYSBOOKNLIFECARDINFO,
    component: dynamic(() => import("../../modal/PaysBooknLifeCardInfoModal")),
  },
  {
    type: ModalTypes.BOOKNPAYGUIDE,
    component: dynamic(() => import("../../modal/BookNPayGuideModal")),
  },
  {
    type: ModalTypes.SELFAUTH,
    component: dynamic(() => import("../../modal/SelfAuthModal")),
  },
  {
    type: ModalTypes.UNLOCKCASH,
    component: dynamic(() => import("../../modal/UnlockCashModal")),
  },
  {
    type: ModalTypes.CELLPHONEAUTH,
    component: dynamic(() => import("../../modal/CellPhoneAuthModal")),
  },
  {
    type: ModalTypes.HISTORYDETAIL,
    component: dynamic(() => import("../../modal/HistoryDetailModal")),
  },
  {
    type: ModalTypes.DORMANCY_RELEASE,
    component: dynamic(() => import("../../modal/user/DormancyReleaseModal")),
  },
  {
    type: ModalTypes.USER_VERIFICATION,
    component: dynamic(() => import("../../modal/user/UserVerificationModal")),
  },
  {
    type: ModalTypes.THIRD_PARTY_OFFER,
    component: dynamic(() => import("../../modal/user/ThirdPartyOfferModal")),
  },
  {
    type: ModalTypes.PUSH_AGREE,
    component: dynamic(() => import("../../modal/user/PushAgreeModal")),
  },
  {
    type: ModalTypes.RECOMMEND_CASH_UNLOCK,
    component: dynamic(
      () => import("../../modal/user/RecommendCashUnlockModal")
    ),
  },
  {
    type: ModalTypes.CHANGE_PUSH_AGREE,
    component: dynamic(() => import("../../modal/user/PushAgreeCompleteModal")),
  },
  {
    type: ModalTypes.KAKAO_AUTH,
    component: dynamic(() => import("../../modal/auth/KakaoAuthModal")),
  },
  {
    type: ModalTypes.KAKAO_AUTH_CONFIRM,
    component: dynamic(() => import("../../modal/auth/KakaoAuthConfirmModal")),
  },
  {
    type: ModalTypes.TERMS_AGREE,
    component: dynamic(() => import("../../modal/auth/TermsAgreeModal")),
  },
  {
    type: ModalTypes.USE_DETAIL,
    component: dynamic(() => import("../../modal/use/UseDetailModal")),
  },
  {
    type: ModalTypes.MAIN_POPUP,
    component: dynamic(() => import("../../modal/main/MainPopupModal")),
  },
  {
    type: ModalTypes.DEPOSIT_WITHOUT_BANKBOOK,
    component: dynamic(
      () => import("../../modal/history/DepositWithoutBankbookModal")
    ),
  },
  {
    type: ModalTypes.FAQ_DETAIL,
    component: dynamic(() => import("../../modal/faq/FaqDetailModal")),
  },
  {
    type: ModalTypes.EXCHANGE_CONFIRM,
    component: dynamic(
      () => import("../../modal/exchange/ExchangeConfirmModal")
    ),
  },
  {
    type: ModalTypes.GIFT_RETRY_MODAL,
    component: dynamic(() => import("../../modal/history/GiftRetryListModal")),
  },
  {
    type: ModalTypes.SELECT_REFUND_BANK,
    component: dynamic(() => import("../../modal/history/SelctBankModal")),
  },
  {
    type: ModalTypes.GIFT_RETRY_CONTACT,
    component: dynamic(
      () => import("../../modal/history/GiftRetryContactModal")
    ),
  },
  {
    type: ModalTypes.DEPOSIT_CANCEL_MODAL,
    component: dynamic(
      () => import("../../modal/history/CancelDepositInfoModal")
    ),
  },
  {
    type: ModalTypes.DEPOSIT_CANCEL_MODAL_TEXT,
    component: dynamic(
      () => import("../../modal/history/CancelDepositInfoTextModal")
    ),
  },
  {
    type: ModalTypes.SELECT_ACCOUNT,
    component: dynamic(() => import("../../modal/user/SelectAccountModal")),
  },
  {
    type: ModalTypes.CASH_LIMIT,
    component: dynamic(() => import("../../modal/user/CashLimitAlertModal")),
  },
  {
    type: ModalTypes.BULK_EXCHANGE,
    component: dynamic(() => import("../../modal/exchange/BulkExchangeModal")),
  },
  {
    type: ModalTypes.BULK_EXCHANGE_RESULT,
    component: dynamic(
      () => import("../../modal/exchange/BulkExchangeResultModal")
    ),
  },
  {
    type: ModalTypes.CASH_LIMIT_CONFIRM,
    component: dynamic(
      () => import("../../modal/user/CashLimitConfirmAlertModal")
    ),
  },
  {
    type: ModalTypes.INICIS_AGREEMENT_POPUP,
    component: dynamic(() => import("../../modal/auth/InicisAgreementModal")),
  },
  {
    type: ModalTypes.INICIS_AGREEMENT_CONTENT_POPUP,
    component: dynamic(
      () => import("../../modal/auth/InicisAgreementContentModal")
    ),
  },
  {
    type: ModalTypes.CASHLOCK_ONE_HOUR_AGREE_POPUP,
    component: dynamic(
      () => import("../../modal/user/CashLockOneHourAgreeModal")
    ),
  },
  {
    type: ModalTypes.RECAPTCHA,
    component: dynamic(
      () => import("../../modal/auth/RecaptchaModal")
    ),
  },
];
