import { useMutation, UseMutationOptions } from "react-query";
import { ErrorType } from "../../net/Request";
import AuthRepository from "../../net/repository/AuthRepository";
import { EcouponTokenVO } from "../../net/models/EcouponTokenVO";

const useECouponToken = () => AuthRepository.getEcouponToken();

export const useECouponTokenMutation = (options?: UseMutationOptions<EcouponTokenVO, ErrorType>) => {
  return useMutation(useECouponToken, {
    ...options,
    onSuccess: (data, variables, context) => {
      // queryClient.resetQueries(tokenKeys.all);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
