import { ResponseData } from "./models/base/ResponseData";
import { xApiKey } from "./RemoteConfig";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { decrypt } from "../common/utils/CryptoUtils";
import Cookies from "js-cookie";
// import * as Sentry from "@sentry/react";

export const defaultAxios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL ?? "",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const authAxios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL ?? "",
  headers: {
    "Content-Type": "application/json",
  },
});

export const fileuploadAxios: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL ?? "",
  timeout: 30000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const requestHandler = async (request: AxiosRequestConfig) => {
  if (request.headers) {
    request.headers["x-api-key"] = xApiKey;
  }

  console.info(`axios Request`, request.url, request);

  return request;
};

const requestAuthHandler = async (request: AxiosRequestConfig) => {
  if (request.headers) {
    request.headers["x-api-key"] = xApiKey;
    request.headers["Authorization"] = `Bearer ${decrypt(
      Cookies.get("token") ?? ""
    )}`;
  }

  console.info(`axios Request`, request.url, request);

  return request;
};

const requestFileHandler = async (request: AxiosRequestConfig) => {
  if (request.headers) {
    request.headers["x-api-key"] = xApiKey;
    request.headers["Content-Type"] = "multipart/form-data";
    request.headers["Authorization"] = `Bearer ${decrypt(
      Cookies.get("token") ?? ""
    )}`;
  }

  console.info(`axios Request`, request.url, request);

  return request;
};

const responseHandler = async (response: AxiosResponse) => {
  console.info("axios Response:", response.request.responseURL, response);

  const data = response.data as ResponseData<any>;

  // if (data.resultCd !== API_OK) {
  //   Sentry.captureMessage(JSON.stringify(data));
  // }

  return response;
};
axios.defaults.withCredentials = true;
defaultAxios.interceptors.request.use(requestHandler);
defaultAxios.interceptors.response.use(responseHandler);

authAxios.interceptors.request.use(requestAuthHandler);
authAxios.interceptors.response.use(responseHandler);

fileuploadAxios.interceptors.request.use(requestFileHandler);
fileuploadAxios.interceptors.response.use(responseHandler);
