import dynamic from "next/dynamic";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { Fade } from "@mui/material";
import styled from "./HeaderGnb.module.css";
import { useRouter } from "next/router";
import { useFormSubmit } from "../../../hooks/useFormSubmit";
import { giftShopBaseUrl } from "../../../net/RemoteConfig";
import { useAuth } from "../../../lib/context/AuthContext";
import { isNullorEmpty } from "../../../common/utils/CommonUtils";
import { useECouponTokenMutation } from "../../../query/auth/useECouponTokenMutation";

enum Tabs {
  CASH_CHARGE = "menu01",
  CASH_EXCHANGE = "menu02",
  CASH_USE = "menu03",
  PRESENT = "menu04",
  ECOUPON = "menu05",
  EVENT = "menu06",
  HELP_CENTER = "menu07",
}

type TabsState = {
  type: Tabs;
  name: any;
  page: string;
  component: any;
};

export const TabTable: TabsState[] = [
  {
    type: Tabs.CASH_CHARGE,
    name: "캐시충전",
    page: "/cashcharge",
    component: dynamic(() => import("./hover/CashChargeHoverView")),
  },
  {
    type: Tabs.CASH_EXCHANGE,
    name: "캐시교환",
    page: "/exchange",
    component: dynamic(() => import("./hover/CashExchangeHoverView")),
  },
  {
    type: Tabs.CASH_USE,
    name: <>상품권<i>&middot;</i>캐시사용</>,
    page: "/use",
    component: dynamic(() => import("./hover/CashUseHoverView")),
  },
  {
    type: Tabs.PRESENT,
    name: <>구매</>,
    page: "/present/?presentType=giftcard",
    component: dynamic(() => import("./hover/CashPresentHoverView")),
  },
  {
    type: Tabs.ECOUPON,
    name: "e쿠폰",
    page: "",
    component: null,
  },
  {
    type: Tabs.EVENT,
    name: "이벤트",
    page: "/event",
    component: null,
  },
  {
    type: Tabs.HELP_CENTER,
    name: "고객센터",
    page: "/customerCenter",
    component: dynamic(() => import("./hover/HelpCenterHoverView")),
  },
];

const HeaderGnbView = () => {
  const router = useRouter()
  const [activeTab, setActiveTab] = useState<Tabs>();
  const [gnbHeight, setGnbHeight] = useState(45);
  const hoverRef = useRef<HTMLDivElement>(null);
  const { hiddenForm, requestSubmit } = useFormSubmit();
  const membTokenMutation = useECouponTokenMutation();

  const onMouseEnter = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tab: Tabs) => {
    setActiveTab(tab);
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setActiveTab(undefined);
  };

  const onHoverClicked = async (type: Tabs) => {
    if (type == Tabs.ECOUPON) {
      const res = await membTokenMutation.mutateAsync();
      requestSubmit({
        url: giftShopBaseUrl,
        data: {
          membId: res.membId ?? "",
          connType: "W",
          orderNum: "",
        },
      });
    }
  };

  useEffect(() => {
    setGnbHeight(45 + (hoverRef.current?.clientHeight ?? 0));
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(undefined)
  }, [router])

  return (
    <div className={styled.gnb} style={{ height: gnbHeight }} onMouseLeave={onMouseLeave}>
      <div className={styled.gnb_wrap}>
        <ul>
          {TabTable.map((item, index) => {
            const isHover = activeTab == item.type;
            const isSelected = item.page.includes(router.pathname);

            return (
              <li key={index} className={isHover || isSelected ? `${styled.ov} ${styled.on}` : ""}>
                {isNullorEmpty(item.page) ? (
                  <a className={styled.dep01} style={{cursor:"pointer"}} onMouseEnter={(e) => onMouseEnter(e, item.type)} onClick={() => onHoverClicked(item.type)}>
                    {item.name}
                  </a>
                ) : (
                  <Link href={item.page}>
                    <a className={styled.dep01} onMouseEnter={(e) => onMouseEnter(e, item.type)}>
                      {item.name}
                    </a>
                  </Link>
                )}

                {item.component && (
                  <div className={styled.dep02} style={{ display: isHover ? "block" : "none" }}>
                    <Fade in={isHover} timeout={300}>
                      <div className={styled.sub_nav} ref={isHover ? hoverRef : null}>
                        <div className={styled.sub_inner}>
                          <item.component />
                        </div>
                      </div>
                    </Fade>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      {hiddenForm}
    </div>
  );
};

export default HeaderGnbView;
