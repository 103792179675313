import { useQueryClient, useMutation, UseMutationOptions } from "react-query";
import { TokenVO } from "../../net/models/TokenVO";
import { ErrorType } from "../../net/Request";
import AuthRepository from "../../net/repository/AuthRepository";

const requestRefreshToken = () => AuthRepository.issueAccessToken();

export const useRefreshTokenMutation = (options?: UseMutationOptions<TokenVO, ErrorType>) => {
  return useMutation(requestRefreshToken, {
    ...options,
    onSuccess: (data, variables, context) => {
      // queryClient.resetQueries(tokenKeys.all);

      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
