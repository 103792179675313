import { encrypt } from "../common/utils/CryptoUtils";
import { dateFormater } from "../common/utils/DateUtils";

export const isDev = process.env.NEXT_PUBLIC_ENV === "debug" || process.env.NEXT_PUBLIC_ENV === "dev";
export const giftShopBaseUrl: string = process.env.NEXT_PUBLIC_GIFT_BASE_URL ?? "";
export const authBaseUrl: string = process.env.NEXT_PUBLIC_AUTH_BASE_URL ?? "";
export const API_OK = "0000";

export const xApiKey: string = process.env.NEXT_PUBLIC_X_API_KEY ?? "";
export const authxApiKey: string = process.env.NEXT_PUBLIC_AUTH_X_API_KEY ?? "";

export const membId = (id: string): string => encrypt(`${id}|${dateFormater("yyyyMMddHHmmss")}`, "ECOUPON");

export const enum ErrorCodes {
  LOGOUT = "4999",
  DORMANCY = "1101",
  LOGIN_FAILED_5_TIMES = "1102",
  LOGIN_PASSWORD_CHANGE = "1104",
  CHANGE_ALREADY_PASSWORD = "1107",
  SYSTEM_MAINTENANCE = "9100",
  ONESTORE_LOGIN_NOT_EXIST_TOKEN = "0001_NotExistToken",
  ONESTORE_LOGIN_EXPIRE_TOKEN = "0001_ExpiredToken",
  ONESTORE_LOGIN_FAILED_TOKEN = "0001_FailedToken",
  DUPLICATE_ACCOUNT = "9000",
  ID_NOT_FOUND = "1106",
  EXIST_USER_EMAIL = "1114",
  EXIST_USER_ID = "1005",
  EXPIRE_TOKEN = "4997",
  SELF_AUTH_EXPIRED = "1008",
}
