import { Grow } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { CashLockState } from "../../../common/constants/Constants";
import { isNullorEmpty, numberWithCommas } from "../../../common/utils/CommonUtils";
import { useModal } from "../../../hooks/useModal";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useAuth } from "../../../lib/context/AuthContext";
import { useLogoutMutation } from "../../../query/user/useUserMutation";
import { AlertProps } from "../../modal/CustomAlertModal";
import { ModalTypes } from "../modal/ModalTable";
import styled from "./HeaderPrivate.module.css";
import { decrypt } from "../../../common/utils/CryptoUtils";

const HeaderPrivateView = () => {
  const router = useRouter()
  const alert = useModal<AlertProps>(ModalTypes.ALERT)
  const ref = useRef<HTMLDivElement>(null);
  
  const [isShowMyPage, setShowMyPage] = useState<boolean>(false);
  const { userInfo, logout } = useAuth();

  const isLockChecked = userInfo?.actStat === CashLockState.LOCKED;
  const isLogined = !!userInfo;

  const logoutMutation = useLogoutMutation();
  
  const clickOutsidehandler = () => {
    setShowMyPage(false);
  };

  useOnClickOutside(ref, clickOutsidehandler);
  
  useEffect(() => {
    setShowMyPage(false)
  }, [router.asPath])

  const handleLoginLogout = () => {
    if (!isLogined) {
      router.push("/auth/login")
      return
    }

    alert.openModal({
      contents: "로그아웃 하겠습니까?",
      confirmText: "확인",
      cancelText: "취소",
      onConfirm: () => {
        logoutMutation.mutate()
        logout("/main");
      },
    });
  };
  
  return (
    <div className={styled.top_menu}>
      <div className={styled.my_cash} style={{ display: isLogined ? "inline-block" : "none" }}>
        <h5 style={{ cursor: "pointer" }} onClick={() => router.push("/mypage?page=01")}>
          <i>내캐시</i>
        </h5>
        <span style={{ cursor: "pointer" }} onClick={() => router.push("/mypage?page=01")}>{numberWithCommas(isNullorEmpty(userInfo?.cashBal) ? "0" : userInfo?.cashBal ?? "0")}원</span>
        <div className={styled.lock_toggle}>
          <div className={styled.checkbox_switch}>
            <input checked={isLockChecked} type="checkbox" className={styled.input_checkbox} onClick={() => router.push("/mypage?page=02")} readOnly />
            <div className={styled.checkbox_animate}>
              <span className={styled.checkbox_off}>잠금해제</span>
              <span className={styled.checkbox_on}>잠금</span>
            </div>
          </div>
        </div>
      </div>

      {isLogined && (
        <a onClick={() => setShowMyPage(!isShowMyPage)} style={{ cursor: "pointer" }}>
          마이페이지
        </a>
      )}

      <a onClick={() => handleLoginLogout()} style={{ cursor: "pointer" }}>
        {isLogined ? "로그아웃" : "로그인"}
      </a>

      {!isLogined && (
        <Link href="/auth/signup/welcome">
          <a>회원가입</a>
        </Link>
      )}

      <Link href={process.env.NEXT_PUBLIC_CN_URL ?? ""}>
        <a className={styled.corp_mall} target="_blank">
          법인몰
        </a>
      </Link>

      <Grow in={isShowMyPage}>
        <div ref={ref} className={styled.mypage_link} style={{ display: "block" }}>
          <ul>
            <li>
              <Link href="/mypage?page=01">
                <a onClick={() => setShowMyPage(false)}>이용내역</a>
              </Link>
            </li>
            <li>
              <Link href="/mypage?page=02">
                <a onClick={() => setShowMyPage(false)}>보안설정</a>
              </Link>
            </li>
            <li>
              <Link href="/mypage?page=03&askMenu=01">
                <a onClick={() => setShowMyPage(false)}>문의내역</a>
              </Link>
            </li>
            <li>
              <Link href="/mypage?page=04">
                <a onClick={() => setShowMyPage(false)}>내정보변경</a>
              </Link>
            </li>
          </ul>
        </div>
      </Grow>
    </div>
  );
};

export default HeaderPrivateView;
