import styled from "@emotion/styled";
import Image from "next/image";
import Link from "next/link";
import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { useBannerListQuery } from "../../../query/main/useBannerListQuery";
import { BannerType, BannerPosition } from "./BannerView";
import layout from "./LeftBanner.module.css";

const leftSettings: Settings = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const StyledSlider = styled(Slider)`
  .slick-list {
    margin: 0 -10px !important;
  }

  .slick-slide > div {
    padding: 0 10px !important;
  }
`;

const LeftBannerView = () => {
  const slider = useRef<Slider>(null);
  const [autoPlay, setAutoPlay] = useState(true);
  const [position, setPosition] = useState(0);

  const bannerQuery = useBannerListQuery({
    params: {
      bnrMaxCount: 15,
      bnrLocate: BannerType.HOMEPAGE,
      bnrPosition: BannerPosition.LEFT_TOP,
    },
    options: {
      select: (data) => {
        if (data.List?.length == 1) {
          data.List?.push({
            bnrId: Math.random(),
            imgUrl: "/assets/images/banner_b_03@2x.png",
            linkUrl: "",
            title: "",
            linkType: "",
          });
        }
        
        return data.List!;
      },
      onSuccess: (data) => {
        console.log("left bannerQuery onSuccess = ", data);
      },
      onError: (err) => {
        console.error("left bannerQuery error = ", err);
      },
    },
  });

  const bannerCount = bannerQuery.data?.length ?? 0;
  
  return (
    <div className={layout.banner_box_w}>
      <div className={`${layout.swiper} ${layout.mainSwiper_w} ${bannerCount > 1 ? "" : layout.wide}`}>
        <div>
          {bannerQuery.data &&
            (bannerCount > 1 ? (
              <StyledSlider {...leftSettings} ref={slider} beforeChange={(_, next: number) => setPosition(next)}>
                {bannerQuery.data?.map((data) => {
                  return (
                    <Link key={data.bnrId} href={data.linkUrl}>
                      <a id={`main_topBanner_${data.bnrId}`} target={data.linkType === "1" ? "_blank" : "_self"}>
                        <div key={data.bnrId} className={layout.swiper_slide} style={{ cursor: "pointer" }}>
                          <Image src={data.imgUrl ?? ""} width={390} height={350} alt="banner 설명 입력" layout="fixed" priority={true} />
                        </div>
                      </a>
                    </Link>
                  );
                })}
              </StyledSlider>
            ) : (
              <div className={layout.swiper_slide}>
                <Image src="/assets/images/main_banner_xl@2x.png" width={800} height={350} alt="banner 설명 입력" />
              </div>
            ))}
        </div>

        {bannerCount > 2 && (
          <div className={layout.swiper_btn_wrap}>
            <div className={layout.swiper_btn}>
              <div className={layout.prev} onClick={() => slider?.current?.slickPrev()} />
              <div className={`${layout.swiper_pagination_fraction}`} tabIndex={0}>
                <span className={layout.swiper_pagination_current}>{position + 1}</span> /{" "}
                <span className={layout.swiper_pagination_total}>{bannerQuery.data?.length}</span>
              </div>
              <div className={layout.start_stop}>
                <button
                  className={`${layout.start} ${autoPlay ? layout.off : layout.on}`}
                  aria-label="배너 재생버튼"
                  onClick={() => {
                    slider.current?.slickPlay();
                    setAutoPlay(true);
                  }}
                />
                <button
                  className={`${layout.stop} ${autoPlay ? layout.on : layout.off}`}
                  aria-label="배너 정지버튼"
                  onClick={() => {
                    slider.current?.slickPause();
                    setAutoPlay(false);
                  }}
                />
              </div>
              <div className={layout.next} onClick={() => slider?.current?.slickNext()} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftBannerView;
