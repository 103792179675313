import crypto from "crypto-js";
import * as iconv from "iconv-lite";

export const CryptoConfigs = {
  BOOKLIFE: {
    keyHash: crypto.enc.Hex.parse(process.env.NEXT_PUBLIC_AES_KEY ?? ""),
    ivHash: crypto.enc.Hex.parse(process.env.NEXT_PUBLIC_AES_IV_KEY ?? ""),
  },
  BOOKLIFEAUTH: {
    keyHash: crypto.enc.Hex.parse(process.env.NEXT_PUBLIC_AUTH_AES_KEY ?? ""),
    ivHash: crypto.enc.Hex.parse(process.env.NEXT_PUBLIC_AUTH_AES_IV_KEY ?? ""),
  },
  ECOUPON: {
    keyHash: crypto.MD5(process.env.NEXT_PUBLIC_GIFT_AES_KEY ?? ""),
    ivHash: crypto.MD5(process.env.NEXT_PUBLIC_GIFT_AES_IV_KEY ?? ""),
  },
  EVENT: {
    keyHash: crypto.enc.Utf8.parse(process.env.NEXT_PUBLIC_EVENT_AES_KEY ?? ""),
    ivHash: crypto.enc.Utf8.parse(
      process.env.NEXT_PUBLIC_EVENT_AES_IV_KEY ?? ""
    ),
  },
} as const;

export type CryptoType = keyof typeof CryptoConfigs;
function byteArrayToWordArray(ba: string | any[]) {
  var wa: number[] | undefined = [],
    i;
  for (i = 0; i < ba.length; i++) {
    wa[(i / 4) | 0] |= ba[i] << (24 - 8 * i);
  }

  return crypto.lib.WordArray.create(wa, ba.length);
}
export const encrypt = (
  value?: string,
  type: CryptoType = "BOOKLIFE"
): string => {
  if (!value) return "";

  try {
    const encodedBuffer = iconv.encode(value, "EUC-KR");

    const wordArray = crypto.enc.Hex.parse(encodedBuffer.toString("hex"));

    const chiper = crypto.AES.encrypt(wordArray, CryptoConfigs[type].keyHash, {
      iv: CryptoConfigs[type].ivHash,
      padding: crypto.pad.Pkcs7,
      mode: crypto.mode.CBC,
    });

    var base64 = crypto.enc.Base64.stringify(chiper.ciphertext);
    return base64;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const decrypt = (
  value?: string,
  type: CryptoType = "BOOKLIFE"
): string => {
  if (!value) return "";

  try {
    const chiper = crypto.AES.decrypt(value, CryptoConfigs[type].keyHash, {
      iv: CryptoConfigs[type].ivHash,
      padding: crypto.pad.Pkcs7,
      mode: crypto.mode.CBC,
    });

    if (type == "BOOKLIFE") {
      const decodedData = atob(chiper.toString(crypto.enc.Base64));

      const euckrString = iconv.decode(
        Buffer.from(decodedData, "binary"),
        "EUC-KR"
      );

      return euckrString;
    } else {
      return chiper.toString(crypto.enc.Base64);
    }
  } catch (error) {
    console.error("error = ", error);
    return "";
  }
};
