import { BannerVO } from './../../net/models/BannerVO';
import { MainKeys } from './MainKeys';
import { useQuery, UseQueryOptions } from "react-query";
import CommonRepository from '../../net/repository/CommonRepository';
import { ErrorType } from '../../net/Request';

type Params = {
  bnrMaxCount: number
  bnrLocate: string
  bnrPosition: string
}

const fetcher = (params: Params) => {
  return CommonRepository.getBannerList(params);
};

export const useBannerListQuery = ({
  params,
  options
}: {
    params: Params;
    options?: UseQueryOptions<BannerVO, ErrorType, BannerVO[], any>;
}) =>
  useQuery([MainKeys.bannerList(), params.bnrPosition], () => fetcher(params), {
    ...options,
    refetchOnWindowFocus: false,
  });
