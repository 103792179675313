import { MainKeys } from "./mainKey";
import { atom } from "recoil";

export const topLineBannerVisible = atom<boolean>({
  key: MainKeys.TOP_LINE_BANNER_VISIBILITY,
  default: false,
});

export const topTextBannerVisible = atom<boolean>({
  key: MainKeys.TOP_TEXT_BANNER_VISIBILITY,
  default: false,
});

export const inputChange = atom<string>({
  key: MainKeys.INPUT_CHANGE,
  default: "",
});
