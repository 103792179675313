import { LoginBannerVO } from './../models/LoginBannerVO';
import { IpInfoVO } from './../models/IpInfoVO';
import { TermsVO } from './../models/TermsVO';
import { PopupVO } from './../models/PopupVO';
import { BannerVO } from './../models/BannerVO';
import { NoticeVO } from './../models/NoticeVO';
import { API_OK } from '../RemoteConfig';
import CommonService from '../services/CommonService';
import { makeError } from '../Request';
import { ChargeMainListVO } from '../models/ChargeMainListVO';
import { ResponseData } from '../models/base/ResponseData';

class CommonRepository {
  getNoticeList(): Promise<NoticeVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getNoticeList();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getBannerList(params: any): Promise<BannerVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getBannerList(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getPopupList(): Promise<PopupVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getPopupList();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getTermsDetail(params: any): Promise<TermsVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getTermsDetail(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;
      resolve(res.ResultData);
    });
  }

  getChargeGnbList(): Promise<ChargeMainListVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getChargeGnbList();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;
      resolve(res.ResultData);
    });
  }

  getCheckAuthUseAbleTime(params: any): Promise<ResponseData<any>> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getCheckAuthUseAbleTime(params);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  getIpAddress(): Promise<IpInfoVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getIpAddress();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  getLoginBannerList(): Promise<LoginBannerVO> {
    return new Promise(async (resolve, reject) => {
      const res = await CommonService.getLoginBannerList();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }
}

export default new CommonRepository();
