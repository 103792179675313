import { LoginBannerVO } from './../models/LoginBannerVO';
import { IpInfoVO } from './../models/IpInfoVO';
import { TermsVO } from './../models/TermsVO';
import { PopupVO } from './../models/PopupVO';
import { BannerVO } from './../models/BannerVO';
import { NoticeVO } from './../models/NoticeVO';
import { ResponseData } from '../models/base/ResponseData';
import { request } from '../Request';
import { ChargeMainListVO } from '../models/ChargeMainListVO';

const CommonService = {
  getNoticeList: () => request<ResponseData<NoticeVO>>({ url: "/api/Main/GetMainNoticeList", method: "post", data: { ViewCnt: 5, TopViewCnt: 2 } }),
  getBannerList: (params: any) => request<ResponseData<BannerVO>>({ url: "/api/Main/GetMainBanner", method: "post", data: params }),
  getLoginBannerList: () => request<ResponseData<LoginBannerVO>>({ url: "/api/Main/GetLoginBanner", method: "post", data: {} }),
  getPopupList: () => request<ResponseData<PopupVO>>({ url: "/api/Main/GetPopup", method: "post", data: {} }),
  getTermsDetail: (params: any) => request<ResponseData<TermsVO>>({ url: "/api/Office/GetOfficeRule", method: "post", data: params }),
  getChargeGnbList: () => request<ResponseData<ChargeMainListVO>>({ url: "/api/Main/GetChargeList", method: "post", data: {} }),
  getCheckAuthUseAbleTime: (params: any) => request<ResponseData<any>>({ url: "/api/Common/CheckAuthUseableTime", method: "post", data: params }),
  getIpAddress: () => request<ResponseData<IpInfoVO>>({ url: "/api/Setting/GetRemoteIp", method: "post", data: {} }),
};

export default CommonService;