import { FindUserVO } from './../models/FindUserVO';
import { ResponseData } from '../models/base/ResponseData';
import { UserVO } from '../models/UserVO';
import { request, requestFetch } from '../Request';
import { AuthReadyVO } from '../models/AuthReadyVO';

const UserService = {
  requestLogout: () => requestFetch<ResponseData<any>>("/api/Auth/Logout", {}),
  getAuthReadyKey: () => requestFetch<ResponseData<AuthReadyVO>>("/api/Common/Ready", {}),
  requestLogin: (params: any) => requestFetch<ResponseData<UserVO>>("/api/Auth/Login", params),
  requestFindId: (params: any) => request<ResponseData<FindUserVO>>({ url: "/api/Member/FindId", method: "post", data: params }),
  requestChangePassword: (params: any) => request<ResponseData<FindUserVO>>({ url: "/api/Member/ChangePw", method: "post", data: params }, params.myPageYn === "Y"),
  requestSignUp: (params: any) => request<ResponseData<UserVO>>({ url: "/api/Member/Regist", method: "post", data: params }),
  requestThirdTermAgree: (params: any) => request<ResponseData<any>>({ url: "/api/Setting/SetTerms", method: "post", data: params }, true),
  requestPushAgree: (params: any) => request<ResponseData<any>>({ url: "/api/Setting/ChangeMarketingInfo", method: "post", data: params }, true),
  getUserInfo: () => request<ResponseData<UserVO>>({ url: "/api/Member/GetMembInfoV2", method: "post", data: {} }, true),
  checkUserId: (params: any) => request<ResponseData<any>>({ url: "/api/Member/CheckMemberId", method: "post", data: params }),
  checkUserEmail: (params: any) => request<ResponseData<any>>({ url: "/api/Member/CheckMemberEmail", method: "post", data: params }),
  getSignUpInfo: (params: any) => request<ResponseData<any>>({ url: "/api/Member/RegistReady", method: "post", data: params }),
  getThirdTerms: (params: any) => request<ResponseData<any>>({ url: "/api/Setting/GetTermsInfo", method: "post", data: params }, true),
  getUserCashInfo: () => request<ResponseData<UserVO>>({ url: "/api/Member/GetOwnCashInfo", method: "post", data: {} }, true),
  verifyMember: (params: any) => request<ResponseData<any>>({ url: "/api/Member/SearchMember", method: "post", data: params }, true),
  selectAccount: (params: any) => request<ResponseData<any>>({ url: "/api/Member/SelectMembId", method: "post", data: params }),
  requestRejectEmail: (params: any) => request<ResponseData<any>>({ url: "/api/Member/RejectEmail", method: "post", data: params }),
};

export default UserService;
