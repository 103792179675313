import React from "react";
import LeftBannerView from "./LeftBannerView";
import RightBannerView from "./RightBannerView";
import styled from "./Banner.module.css";
import parent from "../Main.module.css";

export const enum BannerPosition {
  RIGHT_TOP = "3",
  LEFT_BOTTOM = "4",
  LEFT_TOP = "1",
  RIGHT_BOTTOM = "2",
  TOP = "11",
  LINE_TOP = "12",
}

export const enum BannerType {
  HOMEPAGE = "1",
  APP = "2",
}

const BannerView = () => {
  return (
    <section className={styled.banner_area}>
      <div className={parent.inner}>
        <LeftBannerView />
        <RightBannerView />
      </div>
    </section>
  );
};

export default BannerView;
