import { Grow } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import Router from "next/router";
import { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import bookn_logo from "../../../../public/assets/images/logo-paysbooknlife.png";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { useAuth } from "../../../lib/context/AuthContext";
import { subTermsTabChange } from "../../../recoil/company/companyTabAtom";
import styled from "./Footer.module.css";

const FooterView = () => {
  const { isAuthenticated, isFallback } = useAuth()
  const [showSubSidiaryAll, setShowSubsidiaryAll] = useState(false);
  const [showServiceAll, setShowServiceAll] = useState(false);
  const setTermsTab = useSetRecoilState(subTermsTabChange);
  
  const onAllClicked = (isSubsidiary: boolean) => {
    if (isSubsidiary) {
      setShowSubsidiaryAll(!showSubSidiaryAll);
      return;
    }

    setShowServiceAll(!showServiceAll);
  };

  const onOutsideClick = () => {
    console.log("clickOutsidehandler");
    if (showSubSidiaryAll) setShowSubsidiaryAll(false);
    if (showServiceAll) setShowServiceAll(false);
  };

  return (
    <footer>
      <div className={styled.footer}>
        <div className={styled.footer_top}>
          <div className={styled.f_inner}>
            <div className={styled.trems}>
              <Link href="/company/booknlife">
                <a>회사소개</a>
              </Link>
              <Link href="/company/booknlife?page=0301">
                <a  onClick={() => {
                    setTermsTab(0);
                  }}>
                    이용약관
                </a>
              </Link>
              <Link href="/company/booknlife?page=0301">
                <a
                  onClick={() => {
                    setTermsTab(2);
                  }}
                >
                  <b>개인정보처리방침</b>
                </a>
              </Link>
            </div>
            <div className={styled.sns_ar}>
              <Link href="https://facebook.com/paysbooknlife/">
                <a target="_blank" rel="noopener noreferrer">
                  <Image src="/assets/images/facebook.png" alt="페이스북 로고" width={24} height={24} />
                </a>
              </Link>
              <Link href="https://www.instagram.com/book.n.life/">
                <a target="_blank" rel="noopener noreferrer">
                  <Image src="/assets/images/instar.png" alt="인스타그램 로고" width={24} height={24} />
                </a>
              </Link>
            </div>
          </div>
        </div>

        <div className={styled.foot_content}>
          <div className={styled.f_inner}>
            <h1>
              <Image src={bookn_logo} alt="페이즈&북앤라이프 로고" />
            </h1>
            <div className={styled.comp_info}>
              <h4>(주)페이즈북앤라이프</h4>
              <p>
                대표이사 : 전우정
                <br />
                서울시 성동구 성수이로 10길 14, 1305호 (에이스하이엔드 성수타워)
                <br />
                사업자등록번호 288-86-02320&nbsp;&nbsp;&nbsp;&nbsp;통신판매업신고번호 제 2021-서울성동-02767호
                <br />
                개인정보보호 책임자 : 김덕수
              </p>
              <p className={styled.copy}>Copyright © Pay’s booknlife. All rights reserved.</p>
            </div>

            <div className={styled.cs_info}>
              <h4>고객센터</h4>
              <div className={styled.tel}>
                <p>1544-5111</p>
                <button onClick={() => {
                  Router.push("/customerCenter");
                }}>
                  <span>온라인문의</span>
                </button>
              </div>
              <div className={styled.info}>
                <span className={styled.time}>평일 09:30 ~ 18:00 (점심시간 12:00 ~ 13:00)</span>
                <span className={styled.fax}>Fax. 02-461-3443</span>
                <span className={styled.mail}>
                  <em>제휴<i>&middot;</i>가맹, 상품권 구매 문의</em>
                  <em>booknlife@paysservice.com</em>
                </span>
              </div>
              <div className={styled.quick_link}>
                <SubSidiaryView showAll={showSubSidiaryAll} onClick={onAllClicked} onOutsideClick={onOutsideClick} />
                <ServiceView showAll={showServiceAll} onClick={onAllClicked} onOutsideClick={onOutsideClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

type AllProps = {
  showAll: boolean;
  onClick: (isSubsidiary: boolean) => void;
  onOutsideClick: () => void;
};

const SubSidiaryView = (props: AllProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const clickOutsidehandler = () => {
    props.showAll && props.onOutsideClick();
  };

  useOnClickOutside(ref, clickOutsidehandler);

  return (
    <div ref={ref} className={styled.quick_link_ar} style={{ cursor: "pointer" }}>
      <Grow in={props.showAll}>
        <div className={styled.link_box} style={{ display: "block" }}>
          <Link href="https://www.coopnc.com/kr/index.html">
            <a target="_blank" rel="noopener noreferrer">
              쿠프마케팅
            </a>
          </Link>
          <Link href="http://www.paysservice.com/Web/main">
            <a target="_blank" rel="noopener noreferrer">
              한국페이즈서비스
            </a>
          </Link>
          <Link href="https://www.booknlife.com/main">
            <a target="_blank" rel="noopener noreferrer">
              페이즈북앤라이프
            </a>
          </Link>
          <Link href="http://www.coopfimaps.com/#page1">
            <a target="_blank" rel="noopener noreferrer">
              쿠프파이맵스
            </a>
          </Link>
          <Link href="http://www.grucompany.co.kr/index.html">
            <a target="_blank" rel="noopener noreferrer">
              그루컴퍼니
            </a>
          </Link>
          <Link href="https://www.coopnc.com/en/index.html">
            <a target="_blank" rel="noopener noreferrer">
              쿠프앤글로벌
            </a>
          </Link>
        </div>
      </Grow>
      <div className={props.showAll ? `${styled.btn_site} ${styled.on}` : styled.btn_site} onClick={() => props.onClick(true)}>
        계열사 전체보기
      </div>
    </div>
  );
};

const ServiceView = (props: AllProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const clickOutsidehandler = () => {
    props.showAll && props.onOutsideClick();
  };

  useOnClickOutside(ref, clickOutsidehandler);

  return (
    <div ref={ref} className={styled.quick_link_ar} style={{ cursor: "pointer" }}>
      <Grow in={props.showAll}>
        <div className={styled.link_box2} style={{ display: "block" }}>
          <Link href="http://www.paysshop.co.kr/">
            <a target="_blank" rel="noopener noreferrer">
              페이즈샵
            </a>
          </Link>
          <Link href="https://lite.inumber.co.kr/index">
            <a target="_blank" rel="noopener noreferrer">
              아이넘버 라이트
            </a>
          </Link>
          {/* <Link href="https://biz.inumber.co.kr/">
            <a target="_blank" rel="noopener noreferrer">
              아이넘버 비즈
            </a>
          </Link> */}
          <Link href="https://www.inumber.co.kr/">
            <a target="_blank" rel="noopener noreferrer">
              아이넘버 고객센터
            </a>
          </Link>
          <Link href="http://www.familyvoucher.com/">
            <a target="_blank" rel="noopener noreferrer">
              패밀리바우처
            </a>
          </Link>
          <Link href="https://www.paysgift.com/">
            <a target="_blank" rel="noopener noreferrer">
              페이즈기프트
            </a>
          </Link>
          <Link href="https://www.paysgift.com/Exchange/Coupon/SmsAuth">
            <a target="_blank" rel="noopener noreferrer">
              페이즈 통합교환소
            </a>
          </Link>
          <Link href="http://www.booknlife.com/main">
            <a target="_blank" rel="noopener noreferrer">
              북앤라이프 도서문화상품권
            </a>
          </Link>
          <Link href="https://cm.booknlife.com/ma/main.ma">
            <a target="_blank" rel="noopener noreferrer">
              북앤라이프 법인몰
            </a>
          </Link>
        </div>
      </Grow>

      <div className={props.showAll ? `${styled.btn_site2} ${styled.on}` : styled.btn_site2} onClick={() => props.onClick(false)}>
        서비스 전체보기
      </div>
    </div>
  );
};

export default FooterView;
