import React from "react";
import styled from "./CustomArrow.module.css";

type DotProps = {
  onNext(): void;
  onPrev(): void;
  onGoto(position: number): void;
  dots: number;
  activeDot: number;
};

const CustomArrowDot = (props: DotProps) => {
  return (
    <div className={styled.swiper_btn_wrap}>
      <div className={styled.swiper_btn}>
        <div className={styled.prev} onClick={() => props.onPrev()} />
        <CustomDot {...props} />
        <div className={styled.next} onClick={() => props.onNext()} />
      </div>
    </div>
  );
};

const CustomDot = (props: DotProps) => {
  return (
    <div className={`${styled.swiper_pagination_bullets} ${styled.swiper_pagination_horizontal}`} style={{cursor:"pointer"}}>
      {[...Array(props.dots).keys()].map((number, index) => (
        <span
          key={index}
          className={`${styled.swiper_pagination_bullet} ${index == props.activeDot ? styled.swiper_pagination_bullet_active : ""}`}
          role="button"
          onClick={() => props.onGoto(index)}
        />
      ))}
    </div>
  );
};

export default CustomArrowDot;
