import { ModalTable, ModalTypes } from "../components/common/modal/ModalTable";
import { useRecoilState, useResetRecoilState } from "recoil";
import { modalsSelectorFamily } from "../recoil/modal/modalAtom";

export const useModal = <T>(modalId: ModalTypes) => {
  const [_, setModal] = useRecoilState(modalsSelectorFamily(modalId));
  const resetModal = useResetRecoilState(modalsSelectorFamily(modalId));

  const openModal = async (props?: T) =>
    new Promise((resolve) => {
      const preConfig = ModalTable.find((mi) => mi.type === modalId);

      setModal((current) => ({
        ...current,
        isOpen: true,
        component: preConfig?.component,
        props: {
          ...props,
          onConfirm: (data: any) => {
            const callback = props as any;
            callback?.onConfirm && callback?.onConfirm(data);
            resolve(true);
          },
          onCancel: () => {
            const callback = props as any;
            callback?.onCancel && callback?.onCancel();
            resolve(false);
          },
        },
      }));
    });

  const closeModal = () => {
    resetModal();
  };

  const hideModal = () => {
    setModal((current) => ({ ...current, isOpen: false }));
  };

  return { openModal, closeModal, hideModal };
};
