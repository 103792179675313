import styled from "@emotion/styled";
import Image from "next/image";
import Link from "next/link";
import React, { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import rightBanner01 from "../../../../public/assets/images/banner_b_01.png";
import rightBanner02 from "../../../../public/assets/images/banner_b_02.png";
import { useBannerListQuery } from "../../../query/main/useBannerListQuery";
import { BannerPosition, BannerType } from "./BannerView";
import CustomArrowDot from "./CustomArrowDot";
import layout from "./RightBanner.module.css";

const rightData = [
  {
    id: 1,
    image: rightBanner01,
  },
  {
    id: 2,
    image: rightBanner02,
  },
];

const rightSettings: Settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  fade: true,
  speed: 1000,
};

const StyledSlider = styled(Slider)`
  .slick-active {
    z-index: 999;
  }
`;

const RightBannerView = () => {
  const slider = useRef<Slider>(null);
  const [position, setPosition] = useState(0);

  const bannerQuery = useBannerListQuery({
    params: {
      bnrMaxCount: 5,
      bnrLocate: BannerType.HOMEPAGE,
      bnrPosition: BannerPosition.RIGHT_TOP,
    },
    options: {
      select: (data) => data.List!,
      onSuccess: (data) => {
        console.log("right bannerQuery onSuccess = ", data);
      },
      onError: (err) => {
        console.error("right bannerQuery error = ", err);
      },
    },
  });

  const bannerCount = bannerQuery.data?.length ?? 0;

  return (
    <div className={layout.banner_box_s}>
      <div className={`${layout.swiper} ${layout.mainSwiper_s}`}>
        <div>
          {bannerQuery.data &&
            (bannerCount > 0 ? (
              <StyledSlider {...rightSettings} beforeChange={(_, next: number) => setPosition(next)} ref={slider}>
                {bannerQuery.data?.map((data) => {
                  return (
                    <div key={data.bnrId} className={layout.swiper_slide}>
                      <div className={layout.banner_box}>
                        <Link href={data.linkUrl}>
                          <a id={`main_eventBanner_${data.bnrId}`} target={data.linkType === "1" ? "_blank" : "_self"}>
                            <Image src={data.imgUrl ?? ""} alt="banner 설명 입력" layout="fill" priority={true} />
                            <h5>{data.title}</h5>
                          </a>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </StyledSlider>
            ) : (
              <div className={layout.swiper_slide}>
                <Image src="/assets/images/banner_b_03@2x.png" width={400} height={350} alt="banner 설명 입력" layout="fixed" />
              </div>
            ))}
        </div>

        {bannerCount > 0 && (
          <CustomArrowDot
            onNext={() => slider?.current?.slickNext()}
            onPrev={() => slider?.current?.slickPrev()}
            onGoto={(position: number) => slider?.current?.slickGoTo(position)}
            activeDot={position}
            dots={bannerQuery.data?.length ?? 0}
          />
        )}
      </div>
    </div>
  );
};

export default RightBannerView;
