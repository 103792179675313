import FooterView from "./footer/FooterView";
import HeaderView from "./header/HeaderView";
import layout from "../../../styles/Layout.module.css"

type AppLayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: AppLayoutProps) => {
  return (
    <div className={layout.wrapper}>
      <HeaderView />
      <main>{children}</main>
      <FooterView />
    </div>
  );
};

export default Layout;
