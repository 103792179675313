import { useEffect } from "react";
import { authAxios } from "../../net/CustomAxios";
import { useAuth } from "../../lib/context/AuthContext";
import { AxiosError } from "axios";
import { useRouter } from "next/router";

type AppLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: AppLayoutProps) => {
  const router = useRouter()
  const { isFallback } = useAuth()
  
  useEffect(() => {
    const resInterceptor = (response: any) => {
      console.log("AxiosLayout response = ", response);
      return response;
    };

    const errInterceptor = (error: any) => {
      const { response } = error as unknown as AxiosError;
      console.log("AxiosLayout error = ", response);

      if (response?.status === 401) {
        isFallback(undefined, true);
      }

      return Promise.reject(error);
    };

    const interceptor = authAxios.interceptors.response.use(resInterceptor, errInterceptor);

    return () => authAxios.interceptors.response.eject(interceptor);
  }, [router.asPath]);

  return <>{children}</>;
};

export default AuthLayout;
