import { TabKeys } from './companyTabKey';
import { atom } from "recoil";
import { TermsVO } from '../../net/models/TermsVO';

export const mainTabChange = atom<number>({
  key: TabKeys.COMPANY_TAB_CHANGE,
  default: 0,
});

export const subGiftTabChange = atom<number>({
  key: TabKeys.GIFTCARD_TAB_CHANGE,
  default: 0,
});

export const subTermsTabChange = atom<number>({
  key: TabKeys.TERMS_TAB_CHANGE,
  default: 0,
});

export const subTermsSeq = atom<number>({
  key: TabKeys.TERMS_SEQ,
  default: 0,
});

export const subTermsContents = atom<string>({
  key: TabKeys.TERMS_CONTENTS,
  default: "",
});

export const viewPosition = atom<number>({
  key: TabKeys.VIEW_POSITION,
  default: 0,
});