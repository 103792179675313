import styled from "@emotion/styled";
import Cookies from "js-cookie";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { ChangeEvent, useEffect, useState } from "react";
import Slider from "react-slick";
import { useRecoilState } from "recoil";
import logo from "../../../../public/assets/images/logo-logo-booknlife.svg";
import { ModalExpire } from "../../../common/constants/Constants";
import { afterDate } from "../../../common/utils/DateUtils";
import { useBannerListQuery } from "../../../query/main/useBannerListQuery";
import { topLineBannerVisible, topTextBannerVisible } from "../../../recoil/main/mainAtom";
import { BannerPosition, BannerType } from "../../main/banner/BannerView";
import HeaderGnbView from "./HeaderGnbView";
import HeaderPrivateView from "./HeaderPrivateView";
import layout from "./HeaderView.module.css";

const slideSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  fade: true,
  speed: 1000,
};

const StyledSlider = styled(Slider)`
  .slick-active {
    z-index: 999;
  }
`;

const HeaderView = () => {
  const router = useRouter();
  const [_, setShowTextBanner] = useRecoilState(topTextBannerVisible);
  const [showLineBanner, setShowLineBanner] = useRecoilState(topLineBannerVisible);
  const [showTopBanner, setShowTopBanner] = useState(false);
  const [isAfterDay, setAfterDay] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);

  const canShowTopBanner = !Cookies.get(ModalExpire.MAIN_TOP_BANNER_EXPIRE) && showTopBanner;

  const topBannerQuery = useBannerListQuery({
    params: {
      bnrMaxCount: 1,
      bnrLocate: BannerType.HOMEPAGE,
      bnrPosition: BannerPosition.TOP,
    },
    options: {
      select: (data) => data.List!,
      onSuccess: (data) => {
        setShowTopBanner(data.length > 0);
        setShowTextBanner(data.length > 0);
        console.log("topBannerQuery onSuccess = ", data);
      },
      onError: (err) => {
        console.error("topBannerQuery error = ", err);
      },
    },
  });

  const lineBannerQuery = useBannerListQuery({
    params: {
      bnrMaxCount: 3,
      bnrLocate: BannerType.HOMEPAGE,
      bnrPosition: BannerPosition.LINE_TOP,
    },
    options: {
      select: (data) => data.List!,
      onSuccess: (data) => {
        console.log("lineBannerQuery onSuccess = ", data);
        setShowLineBanner(data.length > 0);
      },
      onError: (err) => {
        console.error("lineBannerQuery error = ", err);
      },
    },
  });

  const handleTopBannerClose = () => {
    setShowTopBanner(false);
    setShowTextBanner(false);
    
    if (!isAfterDay) return;

    // 당일 00시까지 쿠키 설정
    const expireDate = new Date(afterDate({ day: 1 }));
    expireDate.setHours(0, 0, 0, 0);

    Cookies.set(ModalExpire.MAIN_TOP_BANNER_EXPIRE, "true", { expires: expireDate, path: "/main" });
  };

  const handleScroll = () => {
    if (!showTopBanner) return;
    
    setShowTextBanner(window.scrollY < 11);
    setScrollPos(window.scrollY);
  };

  useEffect(() => {
    if (router.pathname === "/main") {
      setShowTopBanner((topBannerQuery.data?.length ?? 0) > 0);
    }
  }, [router.pathname]);

  useEffect(() => {
    const timer = setInterval(() => {
      window.addEventListener("scroll", handleScroll);
    }, 100);
    return () => {
      clearInterval(timer);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showTopBanner]);

  return (
    <header>
      <div className={layout.header}>
        {router.pathname == "/main" && topBannerQuery.data && topBannerQuery.data.length > 0 && (
          <div
            className={`${layout.line_banner} ${canShowTopBanner && scrollPos < 10 ? layout.active : ""}`}
            style={{ background: topBannerQuery.data[0].bgColor }}
          >
            <div className={layout.banner_inner}>
              <Link href={topBannerQuery.data[0].linkUrl}>
                <a id={`gnb_bn_${topBannerQuery.data[0].bnrId}`} target={topBannerQuery.data[0].linkType === "1" ? "_blank" : "_self"}>
                  <img src={topBannerQuery.data[0].imgUrl} alt="banner" />
                </a>
              </Link>
              <div className={layout.close_box}>
                <button className={layout.close_banner} onClick={handleTopBannerClose}></button>
                <div className={layout.check_box_today}>
                  <input type="checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => setAfterDay(e.target.checked)} />
                  <label>오늘 하루 그만보기</label>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={layout.header_top}>
          <div className={layout.h_inner}>
            <h1>
              <Link href="/main">
                <a
                  onClick={() => {
                    if (router.pathname == "/main") {
                      router.reload();
                    }
                  }}
                >
                  <Image src={logo} alt="북앤라이프 로고" />
                </a>
              </Link>
            </h1>
            <div className={layout.right_ar}>
              <HeaderPrivateView />
              <HeaderGnbView />
            </div>
          </div>
        </div>
        {router.pathname == "/main" && lineBannerQuery.data && lineBannerQuery.data.length > 0 && (
          <div className={`${layout.in_banner} ${showLineBanner ? layout.on : ""}`}>
            <StyledSlider {...slideSettings}>
              {lineBannerQuery.data.map((data, index) => {
                return (
                  <Link key={index} href={data.linkUrl}>
                    <a target={data.linkType === "1" ? "_blank" : "_self"}>
                      <div className={layout.text_inner}>
                        <i>공지</i>
                        <span>{data.title}</span>
                      </div>
                    </a>
                  </Link>
                );
              })}
            </StyledSlider>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderView;
