import { encrypt } from "./../../common/utils/CryptoUtils";
import { FindUserVO } from "./../models/FindUserVO";
import { API_OK } from "../RemoteConfig";
import { UserVO } from "../models/UserVO";
import UserService from "../services/UserService";
import { makeError } from "../Request";
import { TermsVO } from "../models/TermsVO";

class UserRepository {
  getAuthReadyKey(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.getAuthReadyKey();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  requestLogin(
    id: string,
    password: string,
    readyToken: string,
    recaptcha: string
  ): Promise<UserVO> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestLogin({
        accessType: "H",
        loginType: "ID",
        id: encrypt(id, "BOOKLIFEAUTH"),
        passwd: encrypt(password, "BOOKLIFEAUTH"),
        vrfInfo: readyToken,
        vrtInfo: recaptcha,
      });

      if (res.ResultCd !== API_OK) {
        reject(makeError(res, res.ResultData));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  requestFindId(param: any): Promise<FindUserVO> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestFindId(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  requestChangePassword(param: any): Promise<FindUserVO> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestFindId(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  requestSignUp(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestSignUp(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestChangePassowrd(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestChangePassword(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestThirdTermAgree(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestThirdTermAgree(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestPushAgree(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestPushAgree(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  getUserInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.getUserInfo();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  checkUserId(membId: string): Promise<any> {
    const param = {
      MembId: encrypt(membId),
    };

    return new Promise(async (resolve, reject) => {
      const res = await UserService.checkUserId(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  checkUserEmail(membEmail: string): Promise<any> {
    const param = {
      MembEmail: membEmail,
    };

    return new Promise(async (resolve, reject) => {
      const res = await UserService.checkUserEmail(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  getSignUpInfo(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.getSignUpInfo(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  getThirdTerms(stplatFlag: string): Promise<TermsVO> {
    return new Promise(async (resolve, reject) => {
      const param = {
        stplatFlag: stplatFlag,
      };

      const res = await UserService.getThirdTerms(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  getUserCashInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.getUserCashInfo();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      if (!res.ResultData) return;

      resolve(res.ResultData);
    });
  }

  verifyMember(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.verifyMember(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  selectAccount(param: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.selectAccount(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestLogout(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const res = await UserService.requestLogout();

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }

  requestRejectEmail(email: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const param = {
        membMail: email,
      };

      const res = await UserService.requestRejectEmail(param);

      if (res.ResultCd !== API_OK) {
        reject(makeError(res));
        return;
      }

      resolve(res.ResultData);
    });
  }
}

export default new UserRepository();
