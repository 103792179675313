import { UserVO } from '../../net/models/UserVO';
import { UserKeys } from "./userKey";
import { atom, selector } from "recoil";
import Cookies from 'js-cookie';
import { decrypt } from '../../common/utils/CryptoUtils';
import { NullOrUndefined } from '../../common/constants/Constants';

export const userInfoAtom = atom<UserVO | NullOrUndefined>({
  key: UserKeys.USER_INFO,
  default: undefined,
});

export const selectUserInfo = selector<UserVO | NullOrUndefined>({
  key: UserKeys.SELECT_USER_INFO,
  get: ({ get }) => {
    const user = get(userInfoAtom);
    const token = decrypt(Cookies.get("token") ?? "");

    return token ? user : null;
  },
});

export const updateUserCash = atom<boolean>({
  key: UserKeys.USER_CASH_INFO,
  default: false,
});

export const SignUpStep = {
  USER_AUTH: "1",
  INSER_USER_INFO: "2",
  SIGN_UP_COMPLETE: "3",
} as const;

export type StepType = typeof SignUpStep[keyof typeof SignUpStep];

export const signUpStepAtom = atom<StepType>({
  key: UserKeys.SIGN_UP_STEP,
  default: SignUpStep.USER_AUTH,
});

export const isUnder14User = atom<boolean>({
  key: UserKeys.IS_UNDER_14_USER,
  default: false,
});

