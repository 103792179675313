export const MainKeys = {
  all: ["usePlace"] as const,
  useList: () => [...MainKeys.all, "useList"] as const,
  useDetail: () => [...MainKeys.all, "useDetail"] as const,
  partnerList: () => [...MainKeys.all, "partnerList"] as const,
  noticeList: () => [...MainKeys.all, "noticeList"] as const,
  bannerList: () => [...MainKeys.all, "bannerList"] as const,
  popupList: () => [...MainKeys.all, "popupList"] as const,
  loginBannerList: () => [...MainKeys.all, "loginBannerList"] as const,
};
