import React, { useContext } from "react";
import { NullOrUndefined } from "../../common/constants/Constants";
import { UserVO } from "../../net/models/UserVO";

export const AuthContext = React.createContext(
  {} as {
    userInfo: UserVO | NullOrUndefined;
    isAuthenticated: boolean;
    logout: (redirectTo?: string) => void;
    isFallback: (token?: string, isDynamicAuth?: boolean) => boolean;
  }
);

export const useAuth = () => useContext(AuthContext);
