import { decodeToken } from "react-jwt";
import { giftcardChargeInputValue } from "../../query/charge/giftcardChargeMutation";
import { encrypt } from "./CryptoUtils";
import { v4 as uuidv4 } from "uuid";
import { dateFormater } from "./DateUtils";
import { exchangeCoupon } from "../../query/exchange/useBulkExchangeMutation";

type tokenInfo = {
  exp: number;
  inf: Inf;
};

type Inf = {
  userid: string;
};

export const isTokenExpired = (token: string): boolean => {
  const expireDate = decodeToken<tokenInfo>(token);
  const diff = Math.floor(
    (expireDate?.exp ?? 601000) * 1000 - new Date().getTime()
  );

  // 10분 체크
  return diff < 600000;
};

export const isUserVerified = (token?: string, userId?: string): boolean => {
  if (!token || !userId) return true;

  const decode = decodeToken<tokenInfo>(token);

  // 사용자 아이디 체크
  return decode?.inf.userid === userId;
};

export const getTokenExpireDate = (token: string): Date => {
  const expireDate = decodeToken<tokenInfo>(token);
  return new Date((expireDate?.exp ?? 0) * 1000);
};

export const numberWithCommas = (x: string | number): string =>
  numberWithUnCommas(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const numberWithUnCommas = (x: string | number): string =>
  x.toString().replace(/[^\d]+/g, "");

export const convertJsonToEncryptedString = (value: any): string =>
  encrypt(JSON.stringify(value));

enum OSType {
  macOS = "macOS",
  iOS = "iOS",
  windows = "windows",
  android = "android",
  linux = "linux",
  etc = "etc",
}

export const getOSType = (): OSType => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os: OSType = OSType.etc;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OSType.macOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OSType.iOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OSType.windows;
  } else if (/Android/.test(userAgent)) {
    os = OSType.android;
  } else if (!os && /Linux/.test(platform)) {
    os = OSType.linux;
  }

  return os;
};

export function returnPhoneNumberForamt(str: string): string {
  var cleaned = ("" + str.replace(/\s/g, "")).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  var match2 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3].replace(/\d/g, "*");
  } else if (match2) {
    return match2[1] + "-" + match2[2] + "-" + match2[3].replace(/\d/g, "*");
  }
  return str;
}

export function returnSecurityPinNumberForamt(str: string): string {
  var cleaned = ("" + str.replace(/\s/g, "")).replace(/\D/g, "");

  var match = cleaned.match(/^(\d{4})(\d{4})(\d{4})(\d{4})(\d{4})$/);
  if (match) {
    return (
      match[1] +
      "-" +
      match[2].replace(/\d/g, "*") +
      "-" +
      match[3].replace(/\d/g, "*") +
      "-" +
      match[4] +
      "(" +
      match[5].replace(/\d/g, "*") +
      ")"
    );
  }
  return str;
}

export function returnDateForamt(str: string): string {
  var cleaned = ("" + str.replace(/\s/g, "")).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{4})(\d{2})(\d{2})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return str;
}

export function returnPinForamt(str: string): string {
  var cleaned = ("" + str.replace(/\s/g, "")).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{4})(\d{4})(\d{4})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3] + "-" + match[4];
  }
  return str;
}

export function checkEamilIsValid(str: string): boolean {
  const expression: RegExp =
    /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  const result: boolean = expression.test(str);

  return result;
}
export function isNullorEmpty(str?: string): boolean {
  return str == null || !str || str.length === 0;
}

export function NewlineText(props: string) {
  const text = props;
  const newText = text.split("\n").map((str, index) => {
    return (
      <p key={index}>
        <br /> {str}
      </p>
    );
  });

  return newText;
}

export function checkDuplicateObjectInGiftCard(
  arr: giftcardChargeInputValue[]
): boolean {
  const uniqueValues = new Set(); // 중복을 제거하기 위한 Set 생성

  for (const item of arr) {
    if (uniqueValues.has(item.pinNo)) {
      return true; // 중복된 값이 발견됨
    }
    uniqueValues.add(item.pinNo);
  }
  return false; // 중복된 값이 없음
}

export function removeDuplicateObjectInGiftCard(
  arr: giftcardChargeInputValue[]
): giftcardChargeInputValue[] {
  return arr.reduce(
    (acc: giftcardChargeInputValue[], current: giftcardChargeInputValue) => {
      const isDuplicate = acc.some(
        (item: giftcardChargeInputValue) => item.pinNo === current.pinNo
      );
      if (!isDuplicate) {
        acc.push(current);
      }
      return acc;
    },
    []
  );
}

export const stringToGiftCardChargeType = (
  input: string
): giftcardChargeInputValue[] => {
  function chunkString(str: string, length: number) {
    return str.match(new RegExp(".{1," + length + "}", "g"));
  }

  const returnArr: giftcardChargeInputValue[] = [];

  let tempArray = chunkString(input.replace(/[^0-9]/gi, ""), 20);

  if (tempArray !== null) {
    tempArray.forEach((element) => {
      let tempSubArray = chunkString(element.replace(/[^0-9]/gi, ""), 16);
      if (tempSubArray !== null && tempSubArray.length >= 2) {
        let tempInputValue: giftcardChargeInputValue = {
          pinNo: tempSubArray[0],
          pinPw: tempSubArray[1],
        };

        returnArr.push(tempInputValue);
      }
    });
  }

  return returnArr;
};

export const getTraceNumber = (): string => {
  return encrypt(
    uuidv4().replaceAll("-", "") + dateFormater("yyyyMMddHHmmssSSS")
  );
};

export const stringToCouponExchangeType = (input: string): exchangeCoupon[] => {
  function chunkString(str: string, length: number) {
    return str.match(new RegExp(".{1," + length + "}", "g"));
  }

  const returnArr: exchangeCoupon[] = [];

  let tempArray = chunkString(input.replace(/[^0-9]/gi, ""), 12);

  if (tempArray !== null) {
    tempArray.forEach((element, index) => {
      let tempInputValue: exchangeCoupon = {
        seqNo: index,
        couponNum: element,
      };
      returnArr.push(tempInputValue);
    });
  }

  return returnArr.reduce((acc: exchangeCoupon[], current: exchangeCoupon) => {
    const isDuplicate = acc.some(
      (item: exchangeCoupon) => item.couponNum === current.couponNum
    );
    if (!isDuplicate) {
      acc.push(current);
    }
    return acc;
  }, []);
};