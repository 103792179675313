const SEO = {
  //titleTemplate: "%s | 북앤라이프",
  titleTemplate: "%s",
  title: "도서문화상품권, 북앤라이프",
  keywords: "북앤라이프",
  description: "다양한 사용처에서 문화생활을 즐겨보세요!",

  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
  ],
  additionalMetaTags: [
    {
      name: "viewport",
      content: "width=device-width",
    },
    {
      name: "ormat-detection",
      content: "telephone=no",
    },
  ],
  openGraph: {
    type: "website",
    site_name: "Example",
    images: [{ url: "https://www.booknlife.com/images/common/logo_paysBNL_color01.png" }],
  },
};
export default SEO;
