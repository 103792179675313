import { useEffect, useRef, useState } from 'react';

type SubmitType = {
  url: string,
  data: any
}

export const useFormSubmit = (target: string = "_blank") => {
  const formRef = useRef<HTMLFormElement>(null);
  const [formData, setFormData] = useState<SubmitType>();

  const requestSubmit = (data: SubmitType) => {
    setFormData(data);
  };

  useEffect(() => {
    formRef.current?.submit();
  }, [formData]);


  const hiddenForm = (
    <>
      {formData && (
        <form ref={formRef} action={formData.url} method="POST" target={target}>
          {Object.keys(formData.data).map((key: string) => (
            <input key={key} type="hidden" name={key} value={formData.data[key]} />
          ))}
        </form>
      )}
    </>
  );

  return { hiddenForm, requestSubmit };
};
