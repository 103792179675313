import { useSetRecoilState } from "recoil";
import { FindUserVO } from "./../../net/models/FindUserVO";
import { useQueryClient, useMutation, UseMutationOptions } from "react-query";
import UserRepository from "../../net/repository/UserRepository";
import { ErrorType } from "../../net/Request";
import { UserVO } from "../../net/models/UserVO";
import Cookies from "js-cookie";
import { getTokenExpireDate } from "../../common/utils/CommonUtils";
import { encrypt } from "../../common/utils/CryptoUtils";
import { userInfoAtom } from "../../recoil/user/userAtom";
import { AuthReadyVO } from "../../net/models/AuthReadyVO";

type UserFindParams = {
  idInqType?: string;
  membNm?: string;
  membSex?: string;
  membBirthDate?: string;
  membMail?: string;
  phoneNum?: string;
  ipinCi?: string;
  crtfcId?: string;
  celCertNo?: string;
};

const requestFindId = (params: UserFindParams) =>
  UserRepository.requestFindId(params);

export const useFindIdMutation = (
  options?: UseMutationOptions<FindUserVO, ErrorType, UserFindParams>
) => {
  return useMutation(requestFindId, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

type PassowrdParams = {
  membId?: string;
  crtfcId: string;
  newPw: string;
  checkNewPw: string;
  checkOldPwYn: string;
  myPageYn?: string;
};

const requestChangePassword = (params: PassowrdParams) =>
  UserRepository.requestChangePassowrd(params);

export const usePasswordMutation = (
  options?: UseMutationOptions<UserVO, ErrorType, PassowrdParams>
) => {
  return useMutation(requestChangePassword, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

const requestAuthReadyKey = () => UserRepository.getAuthReadyKey();

export const useAuthReadyMutation = (
  options?: UseMutationOptions<AuthReadyVO, ErrorType>
) => {
  return useMutation(requestAuthReadyKey, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

type UserLoginParams = {
  id: string;
  passwd: string;
  readyToken: string;
  recaptcha: string;
};

const requestLogin = (params: UserLoginParams) =>
  UserRepository.requestLogin(
    params.id,
    params.passwd,
    params.readyToken,
    params.recaptcha
  );

export const useLoginMutation = (
  options?: UseMutationOptions<UserVO, ErrorType, UserLoginParams>
) => {
  const setUserInfo = useSetRecoilState(userInfoAtom);

  return useMutation(requestLogin, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        Cookies.set("token", encrypt(data.accessToken), {
          expires: getTokenExpireDate(data.accessToken),
          path: "/",
        });
        setUserInfo(data);
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

const getUserInfo = () => UserRepository.getUserInfo();

export const useUserInfoMutation = (
  options?: UseMutationOptions<UserVO, ErrorType>
) => {
  return useMutation(getUserInfo, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

export type SignUpParam = {
  CrtfcId: string;
  ParentId?: string;
  MembId: string;
  MembPswd: string;
  MembEmail: string;
  SmsAgreeYn: string;
  PushAgreeYn: string;
  MailAgreeYn: string;
  TermsInfos: TermsInfo[];
};

type TermsInfo = {
  StplatFlag: string;
  StplatSeq: string;
  AgreeYn: string;
};

const requestSignUp = (params: SignUpParam) =>
  UserRepository.requestSignUp(params);

export const useSignUpMutation = (
  options?: UseMutationOptions<UserVO, ErrorType, any>
) => {
  return useMutation(requestSignUp, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

const getUserCashInfo = () => UserRepository.getUserCashInfo();

export const useUserCashInfoMutation = (
  options?: UseMutationOptions<UserVO, ErrorType>
) => {
  return useMutation(getUserCashInfo, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

type AccountParam = {
  selectMembId: string;
  crtfcId: string;
};

const verifyMember = (params: AccountParam) =>
  UserRepository.selectAccount(params);

export const useSelectAccountMutation = (
  options?: UseMutationOptions<any, ErrorType, AccountParam>
) => {
  return useMutation(verifyMember, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};

const requestLogout = () => UserRepository.requestLogout();

export const useLogoutMutation = (
  options?: UseMutationOptions<any, ErrorType>
) => {
  return useMutation(requestLogout, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options && options.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError(error, variables, context) {
      if (options && options.onError) {
        options.onError(error, variables, context);
      }
    },
  });
};
