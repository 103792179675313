import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseApiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? "";
const firebaseAppId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? "";
const firebaseProjectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? "";
const messagingSenderId = process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_ID ?? "";
const googleAnalyticId = process.env.NEXT_PUBLIC_FIREBASE_GA_ID ?? "";

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: `${firebaseProjectId}.firebaseapp.com`,
  projectId: firebaseProjectId,
  storageBucket: `${firebaseProjectId}.appspot.com`,
  messagingSenderId: messagingSenderId,
  appId: firebaseAppId,
  measurementId: googleAnalyticId,
};

const app = initializeApp(firebaseConfig);

let remoteConfigVar;

if (typeof window !== "undefined") {
  remoteConfigVar = getRemoteConfig(app);

  if (process.env.NEXT_PUBLIC_ENV !== "prod") {
    remoteConfigVar.settings.minimumFetchIntervalMillis = 0;
  }

  remoteConfigVar.defaultConfig = {
    datadog_sample_rate: 10,
  };
}

export const remoteConfig = remoteConfigVar;
