import { useRecoilValue } from "recoil";
import { loadingAtom } from "../../../recoil/loading/loadingAtom";
import styled from "./Loading.module.css";

const LoadingView = () => {
  const isLoading = useRecoilValue(loadingAtom)

  if (isLoading === false) return null

  return (
    <div className={styled.Loader}>
      <div className={styled.Loader_inner}>
        <div className={styled.leap_frog}>
          <div className={styled.leap_frog__dot}></div>
          <div className={styled.leap_frog__dot}></div>
          <div className={styled.leap_frog__dot}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingView;
