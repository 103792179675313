import * as Sentry from "@sentry/nextjs";
import { AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { decrypt } from "../common/utils/CryptoUtils";
import { authAxios, defaultAxios, fileuploadAxios } from "./CustomAxios";
import { ResponseData } from "./models/base/ResponseData";
import { authxApiKey, xApiKey } from "./RemoteConfig";
import { datadogRum } from "@datadog/browser-rum";
/*
POST : { url: "targetUrl", method: "post", data: params }
GET : { url: "targetUrl", method: "get", params: params }
*/

export const request = async <T>(config: AxiosRequestConfig, isAuth: boolean = false, needMoreTime: boolean = false): Promise<T> => {
  try {
    const { data } = isAuth ? await authAxios.request<T>({ ...config, timeout : needMoreTime ? 300000 : 30000 }) : await defaultAxios.request<T>({ ...config });
    return data;
  } catch (error) {
    const { response } = error as unknown as AxiosError;

    if (response) {
      console.log("axios response exception = ", response);
    }

    console.log("axios error exception = ", error);
    
    if (response?.status == 401) {
      return Promise.reject();
    }

    datadogRum.addError(error)
    // datadogLogs.logger.error("Error occurred", {}, error as any);
    // Sentry.captureException(error);
    
    const res = {
      ResultCd: response?.status?.toString(),
      ResultMsg: "네트워크 에러 발생",
    };

    return res as T
  }
};

/*
cross domain에서 httponly 쿠키를 응답헤더에 전송하는 API 호출 시 fetch 사용.
*/
// 12.02 auth x api key 변경
export const requestFetch = async <T>(endpoint: string, params: any, method: string = "POST"): Promise<T> => {
  try {
    const requestOptions: RequestInit = {
      credentials: "include",
      method: method,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": authxApiKey,
        Authorization: `Bearer ${decrypt(Cookies.get("token") ?? "")}`,
      },
    };

    console.log("requestFetch url = ", endpoint);
    console.log(`requestFetch body = ${JSON.stringify(params)}, request = `, requestOptions);

    const res = await fetch(process.env.NEXT_PUBLIC_AUTH_BASE_URL + endpoint, { ...requestOptions, body: JSON.stringify(params) });
    const data = await res.json();
    
    console.log("requestFetch res = ", data);

    return data;
  } catch (error) {
    console.log("fetch exception = ", error);

    datadogRum.addError(error);
    // datadogLogs.logger.error("Error occurred", {}, error as any);
    // Sentry.captureException(error);

    const res = {
      ResultCd: "9999",
      ResultMsg: "네트워크 에러 발생"
    }
    
    return res as T
  }
};

export type ErrorType = {
  errorMsg: string;
  errorCode?: string;
  data?: any
};

export const makeError = (res: ResponseData<any>, data?: any): ErrorType => {
  return {
    errorMsg: res.ResultMsg,
    errorCode: res.ResultCd,
    data: data,
  };
};

export const requestFileUpload = async <T>(url:string, formData:FormData, config: AxiosRequestConfig): Promise<T> => {

  try {
    const { data } = await fileuploadAxios.post<T>( url, formData, { ...config})
    return data;
  } catch (error) {
    const { response } = error as unknown as AxiosError;

    if (response) {
      console.log("axios response exception = ", response);
    }

    console.log("axios error exception = ", error);

    datadogRum.addError(error);
    // datadogLogs.logger.error("Error occurred", {}, error as any);
    // Sentry.captureException(error);

    const res = {
      ResultCd: "9999",
      ResultMsg: "네트워크 에러 발생",
    };

    return res as T;
  }
};

