import "../../styles/globals.css";
import "../../styles/common.css";
import "../../styles/font.css";
import type { AppProps } from "next/app";
import React, { ReactElement, ReactNode, useEffect } from "react";
import { NextPage } from "next";
import Layout from "../components/common/BaseLayout";
import { DefaultSeo } from "next-seo";
import SEO from "../common/config/seo.config";
import ModalManager from "../components/common/modal/ModalManager";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoogleTagManager } from "../lib/GoogleTagManager";
import { AuthProvider } from "../lib/context/AuthProvider";
import LoadingView from "../components/common/loading/LoadingView";
import AuthLayout from "../components/common/AuthLayout";
import { datadogRum } from "@datadog/browser-rum";
import { RemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../common/config/firebase.config";

const queryClient = new QueryClient();

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
  requireAuth?: boolean; // 로그인이 필요한 페이지 일 경우 true
  redirectTo?: string; // 로그인 필요한 페이지에서 토큰 만료 시 리다이렉트 할 url(ex: /auth/login)
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

  useEffect(() => {
    getSampleConfigValue();
  }, []);

  const getSampleConfigValue = () => {
    const firebaseRemoteConfig = remoteConfig;
    
    if (firebaseRemoteConfig) {
      fetchAndActivate(firebaseRemoteConfig)
        .then(() => {
          console.log("remote config fetchAndActivate success");
        })
        .catch((err) => {
          console.log("remote config fetchAndActivate failed");
          console.error(err);
        }).finally(() => {
          // get remote config value(default value is 10)
          const sampleRate = getValue(firebaseRemoteConfig, "datadog_sample_rate").asNumber();
          
          initRum(sampleRate);
        });
    }
  };

  const initRum = (sampleRate: number) => {
    console.log("fetchAndActivate sampleRate = ", sampleRate);

    datadogRum.init({
      applicationId: "eaf8dffa-8010-4242-a211-d5ef5f99f7c0",
      clientToken: "pubb42580bb0391ef0c0ddc642d776b58a8",
      site: "datadoghq.com",
      service: process.env.NEXT_PUBLIC_HOMEPAGE_DOMAIN?.replaceAll("https://", "") ?? "",
      env: process.env.NEXT_PUBLIC_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
      sessionSampleRate: sampleRate,
      sessionReplaySampleRate: sampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      enableExperimentalFeatures: ["clickmap", "feature_flags"],
      // APM 트래이싱 연동
      traceSampleRate: 100,
      allowedTracingUrls: [
        "https://webapi.booknlife.com",
        "https://authapi.booknlife.com",
        "https://booknlife.multicon.co.kr",
        "https://booknlife.multicon.co.kr",
        (url) => url.startsWith("https://webapi.booknlife.com"),
      ],
    });

    datadogRum.startSessionReplayRecording();
  };

  if (
    // process.env.NEXT_PUBLIC_ENV === "dev" ||
    process.env.NEXT_PUBLIC_ENV === "prod"
  ) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.info = () => {};
  }

  return (
    <>
      <DefaultSeo {...SEO} />

      <GoogleTagManager />

      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <LoadingView />
          <AuthProvider requireAuth={Component.requireAuth} redirectTo={Component.redirectTo}>
            <ModalManager />
            <AuthLayout>{getLayout(<Component {...pageProps} />)}</AuthLayout>
          </AuthProvider>
        </RecoilRoot>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
